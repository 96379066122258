<template>
  <div>
    <div class="row">
      <div class="col-sm-8 offset-sm-2">
        <div class="row">
          <h1 class="col-sm-12">Incidencias</h1>
        </div>
        
        <div v-if="incidencias.length == 0" class="row">
          <div class="col-sm-12">Al parecer no hay incidencias, puedes continuar</div>
        </div>

        <template v-else>
          <DataGrid :config="dataGridConfig" :data="incidencias" :select="seleccionar" @actions="datagrid_actions">
            <div class="row">
              <div class="col-sm-12 text-right">
                <button class="btn btn-secondary" @click="revisar_incidencia">Revisar incidencia</button>
              </div>
            </div>
          </DataGrid>
        </template>

        <div class="row">
          <div class="col-sm-12 text-right">
            <button class="btn btn-warning mr-2" @click="$emit('retroceder')">Retroceder etapa</button>
            <button class="btn btn-success" v-tooltip:top="'No se puede avanzar hasta que todas las incidencias esten resueltas'" @click="continuar">Continuar</button>
          </div>
        </div>
      </div>
    </div>

    <Detalle v-if="modal_incidencia" :catalogo="catalogo" :solicitud="solicitud" :incidencia="incidencia" @update="$emit('actualizar')" @update_close="actualizar_cerrar" @close="cancelar_modal_incidencias" />
  </div>
</template>

<script type="text/javascript">
  import DataGrid from '@/components/DataGridV2'
  import Detalle from './Incidencia/Detalle'

  import apiCatalogo from '@/apps/formalizacion/api/v2/catalogos'

  export default {
    components: {
      DataGrid, Detalle
    }
    ,props: {
      solicitud: {
        type: Object
        ,default() {
          return {
            id: null
            ,avaluo_incidencias: 0
            ,incidencias: []
          }
        }
      }
    }
    ,data() {
      return {
        dataGridConfig: {
          name: 'incidencias_valuacion'
          ,search: false
          ,cols: {
            subtipo: 'Incidencia'
            ,descripcion: 'Descripción'
            ,estatus: 'Estatus'
          }
          ,mutators: {
            estatus(val) {
              switch(val) {
                case 1:
                  return 'Sin resolver';
                case 2:
                  return 'Resuleto';
                case 0:
                  return 'Cancelado';
              }
            }
          }
        }
        ,catalogo: {
          opciones: []
        }
        ,incidencia: {
          id: null
          ,subtipo: null
        }
        ,modal_incidencia: false
        ,seleccionadas: []
        ,seleccionar: false
        ,modal_resolver: false
      }
    }
    ,mounted() {
      this.obtener_catalogo();
    }
    ,methods: {
      async obtener_catalogo() {
        if (!this.$store.state.formalizacion) {
          try {
            this.catalogo = (await apiCatalogo.buscar_catalogo('incidencias_valuacion')).data;

            this.$store.commit('set',{formalizacion: {incidencias_valuacion: this.catalogo}});
            this.$log.info('catalogo', this.catalogo);
          }catch(e) {
            this.$log.info('error',e);
            this.$helper.showAxiosError(e,'Error');
          }
        }else {
          this.catalogo = this.$store.state.formalizacion.incidencias_valuacion;
        }
      }
      ,buscar_nombre_opcion(valor) {
        let nombre;

        this.catalogo.opciones.forEach(opcion => {
          if (opcion.valor == valor)
            nombre = opcion.nombre;
        })

        return nombre;
      }
      ,continuar() {
        if (!this.avanzar)
          return;

        this.$emit('avanzar');
      }
      ,datagrid_actions(accion, data) {
        if (accion == 'selected') {
          this.seleccionadas = data;
          this.seleccionar = true;
        }
      }
      ,revisar_incidencia() {
        if (this.seleccionadas.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar una incidencia','error','alert');

        if (this.seleccionadas[0].estatus == 2) {
          this.seleccionar = false;
          return this.$helper.showMessage('Error','La incidencia ya esta resuelta','error','alert');
        }

        this.incidencia = this.seleccionadas[0];
        this.modal_incidencia = true;
      }
      ,cancelar_modal_incidencias() {
        this.modal_incidencia = false;
        this.seleccionar = false;
      }
      ,actualizar_cerrar() {
        this.cancelar_modal_incidencias();
        this.$emit('actualizar');
      }
    }
    ,computed: {
      incidencias() {
        let incidencias = [];

        this.solicitud.incidencias.forEach(incidencia => {
          if (incidencia.tipo == 'avaluo') {
            let tmp = this.$helper.clone(incidencia);
            tmp.subtipo = this.buscar_nombre_opcion(tmp.subtipo);
            incidencias.push(tmp);
          }
        })

        return incidencias;
      }
      ,avanzar() {
        let incidencias = this.incidencias;

        let avanzar = true;
        incidencias.forEach(incidencia => {
          if (avanzar && incidencia.estatus != 2)
            avanzar = false;
        })

        return avanzar;
      }
    }
    ,watch: {
      solicitud: {
        handler() {
          let incidencia_sel;

          if (this.incidencia.id)
            this.solicitud.incidencias.forEach(incidencia => {
              if (this.incidencia.id == incidencia.id)
                this.incidencia = incidencia;
            })
        }
        ,deep: true
      }
    }
  }
</script>

<style lang="scss">
  .incidencias_cabecera {
    background-color: #4C4C4C;
    color: #fff;

    div {
      padding: 5px 10px;
    }
  }

  .incidencias_item:nth-child(2n+0) {
    background-color: #E6E6E6;

    div {
      padding: 5px 10px;
    }
  }
</style>